import React, {useEffect} from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
    IonApp, IonContent,
    IonLabel, IonPage,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs, isPlatform,
    setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Collection from './pages/Collection';
import Timetable from './pages/Timetable';
import Settings from './pages/Settings';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import LoginPage from "./pages/login/LoginPage";
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Menu from "./components/Menu";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import BugPairs from "./pages/BugPairs";
import {Bug, Pair, SettingsType} from "./Types";
import {
    ADD10BUGS_PRODUCT_ID,
    getDataFromStorage,
    getObjDataFromStorage,
    getStringDataFromStorage, movingBugs, PRO_PRODUCT_ID, setDataToStorage, startMovingBugsInterval
} from "./util/tools";
import { InAppPurchase2 as iap } from "@ionic-native/in-app-purchase-2";


setupIonicReact();

const isWebapp = true

const App: React.FC = () => {
    const guestUser = "guest" + Math.round(Math.random() * 10000)
    const credentials = localStorage.getItem("loginCredentials") || guestUser
    const creds = credentials.split("+++")
    const [login, setLogin] = React.useState({username: creds[0], login: creds.length > 1, credentials: credentials && credentials.replace(/\+/g, "_")})

    const [dataDir, setDataDir] = React.useState<string>(getStringDataFromStorage("dataDir", login.credentials))
    const [settings, setSettings] = React.useState<SettingsType>(getObjDataFromStorage("settings", login.credentials))

    const [bugs, setBugs] = React.useState<Array<Bug>>(getDataFromStorage("bugs", login.credentials))
    const [pairs, setPairs] = React.useState<Array<Pair>>(getDataFromStorage("pairs", login.credentials))
    const [filteredBugs, setFilteredBugs] = React.useState<Array<Bug>>(bugs)
    const [filteredPairs, setFilteredPairs] = React.useState<Array<Pair>>(pairs)

    const [currentBug, setCurrentBug] = React.useState<Bug | null>(null)

    const [productPrices, setPrices] = React.useState({pro: '$8.88', add10bugs: '$2.53'})
    const [product, setProduct] = React.useState([]) as any

    //initiate initInAppPurchase function
    useEffect(() => {
        const init = async () => {
            await initInAppPurchase()
        }
        init()

    }, [])

    //if on an ios or android device, then get product info
    const initInAppPurchase = () => {
        if ((isPlatform('ios')) || (isPlatform('android'))) {
            iap.verbosity = iap.DEBUG;

            iap.register({
                id: ADD10BUGS_PRODUCT_ID,
                alias: ADD10BUGS_PRODUCT_ID,
                type: iap.CONSUMABLE
            })
            iap.register({
                id: PRO_PRODUCT_ID,
                alias: PRO_PRODUCT_ID,
                type: iap.NON_CONSUMABLE
            })

            iap.ready(() => {
                const proProduct = iap.get(PRO_PRODUCT_ID)
                const add10bugsProduct = iap.get(ADD10BUGS_PRODUCT_ID)
                setPrices({pro: proProduct.price, add10bugs: add10bugsProduct.price})
                setProduct(proProduct)
            })

            iap.refresh();
        }
    }

    useEffect(() => {
        if (!settings.seenMovingBugs && bugs.length > 10 && settings.addingBug) {
            startMovingBugsInterval()
            setDataToStorage("settings", login.credentials, {...settings, seenMovingBugs: true, addingBug: false})
        }
    }, [settings.addingBug, settings.seenMovingBugs, bugs])

    const bugsThatMove = movingBugs(bugs, dataDir)
    return (
  <IonApp>
      {!login.login ? (
          <IonPage>
              <IonContent fullscreen>
                  <div style={{height: '200vh', width: '100vw', position: 'absolute', zIndex: '2', opacity: .5}}/>
                  <LoginPage setLogin={setLogin} />
              </IonContent>
          </IonPage>
      ) : (
          <IonReactRouter>
            <Menu content={
                <>
                  <IonTabs>
                      <IonRouterOutlet>
                          <Route exact path="/login">
                              <IonPage>
                                  <IonContent fullscreen>
                                      <div style={{height: '200vh', width: '100vw', position: 'absolute', zIndex: '2', opacity: .5}}/>
                                      <LoginPage setLogin={setLogin}/>
                                  </IonContent>
                              </IonPage>
                          </Route>
                          <Route exact path="/collection">
                              <Collection creds={login.credentials} bugs={bugs} setBugs={setBugs} settings={settings} filteredBugs={filteredBugs} setFilteredBugs={setFilteredBugs} currentBug={currentBug} setCurrentBug={setCurrentBug} dataDir={dataDir} setDataDir={setDataDir} setSettings={setSettings} setPrices={setPrices} isWebapp={isWebapp} setLogin={setLogin} login={login}/>
                          </Route>
                          <Route exact path="/breed">
                              <BugPairs creds={login.credentials} pairs={pairs} setPairs={setPairs} bugs={bugs} filteredPairs={filteredPairs} setFilteredPairs={setFilteredPairs} dataDir={dataDir}/>
                          </Route>
                          <Route path="/timetable">
                              <Timetable creds={login.credentials}/>
                          </Route>
                          <Route path="/settings">
                              <Settings settings={settings} creds={login.credentials} setSettings={setSettings} product={product} productPrices={productPrices} isWebapp={isWebapp}/>
                          </Route>
                          <Route exact path="/">
                              <Redirect to="/collection"/>
                          </Route>
                      </IonRouterOutlet>
                      <IonTabBar slot="bottom">
                          <IonTabButton tab="collection" href="/collection">
                              <FontAwesomeIcon icon={icon({name: 'bug'})} />
                              <IonLabel>Collection</IonLabel>
                          </IonTabButton>
                          <IonTabButton tab="breed" href="/breed">
                              <FontAwesomeIcon icon={icon({name: 'seedling'})} />
                              <IonLabel>Breed</IonLabel>
                          </IonTabButton>
                          <IonTabButton tab="timetable" href="/timetable">
                              <FontAwesomeIcon icon={icon({name: 'calendar'})} />
                              <IonLabel>Timetable</IonLabel>
                          </IonTabButton>
                          <IonTabButton tab="settings" href="/settings">
                              <FontAwesomeIcon icon={icon({name: 'cog'})} />
                              <IonLabel>Settings</IonLabel>
                          </IonTabButton>
                      </IonTabBar>
                  </IonTabs>
                    {(!settings.seenMovingBugs && bugs.length > 10 && settings.addingBug) && (
                        bugsThatMove
                    )}
                  </>

            } setLogin={setLogin} login={login}/>
          </IonReactRouter>
      )}
  </IonApp>
)}

/*
                          <Route path="/add-bug">
                              <AddBug bug={currentBug} setCurrentBug={setCurrentBug} bugs={bugs} setBugs={setBugs} creds={login.credentials} settings={settings} setFilteredBugs={setFilteredBugs}/>
                          </Route>
                          <Route path="/add-pair">
                              <AddPair bug={null} setCurrentBug={null} bugs={bugs} setPairs={setPairs} creds={login.credentials} pairs={pairs} setFilteredPairs={setFilteredPairs}/>
                          </Route>
 */

export default App;
