import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router";
import {Col, Row, Form, Button} from "react-bootstrap";
import axios from "axios";
import {Bug, BugProps} from "../Types";
import NotificationPopup from "../components/NotificationPopup";
import {BASE_PATH, constructImagePath, hashCode} from "../util/tools";

const AddBug: React.FC<BugProps> = ({bug: bug, setCurrentBug: setCurrentBug, bugs: bugs, setBugs: setBugs, creds: creds, settings: settings, setSettings, setFilteredBugs: setFilteredBugs, dataDir: dataDir}) => {
    const history = useHistory()

    const [notification, setNotification] = React.useState({show: false, text: '', title: ''})
    const [selectedFile, setSelectedFile] = React.useState<File>()

    const changeHandler = (event:any) => {
        setSelectedFile(event.target.files[0])

    }

    const handleDelete = () => {
        const name = document.getElementById("name") as HTMLInputElement

        if (!name?.value || name?.value === "" || !bug?.id) {
            setNotification({show: true, text: 'Cannot delete a bug without a name', title: 'Error deleting bug'})
            setTimeout(() => setNotification({show: false, text: '', title: ''}), 2000)
        }
        else {
            axios.post(BASE_PATH + "/api.php?api&delete=".concat(bug?.id).concat("&creds=").concat(creds))
                .then(() => {
                    setNotification({show: true, text: 'Deleted your bug :(', title: 'Bug Gone'})
                    setTimeout(() => {
                            setNotification({show: false, text: '', title: ''})

                            if (setBugs) {
                                const newBugs = bugs?.filter((oldbug: Bug) => oldbug.id!== bug?.id)
                                setBugs(newBugs)
                                setFilteredBugs(newBugs)
                            }
                            handleBackClick()
                        }, 1500
                    )
                })
                .catch(e => console.log("wrong", e))
        }
    }
    const handleSave = () => {
        const name = document.getElementById("name") as HTMLInputElement
        const sex = document.getElementById("sex") as HTMLInputElement
        const species = document.getElementById("species") as HTMLInputElement
        const locale = document.getElementById("locale") as HTMLInputElement
        const disperse = document.getElementById("disperse") as HTMLInputElement
        const matured = document.getElementById("matured") as HTMLInputElement
        const instar = document.getElementById("instar") as HTMLInputElement
        const origin = document.getElementById("origin") as HTMLInputElement
        const breeder = document.getElementById("breeder") as HTMLInputElement
        const notes = document.getElementById("notes") as HTMLInputElement
        const mother = document.getElementById("mother") as HTMLInputElement
        const father = document.getElementById("father") as HTMLInputElement
        const commonName = document.getElementById("commonName") as HTMLInputElement
        const lastFed = document.getElementById("lastFed") as HTMLInputElement

        if (selectedFile) {
            const formData = new FormData();
            formData.append('img', selectedFile);
            axios.post(BASE_PATH + "/api.php?api&img&creds=".concat(creds), formData)
                .then(e => console.log("got e", e))
                .catch(e => console.log("wrong", e))
        }

        if (!name?.value || name?.value === "") {
            setNotification({show: true, text: 'Cannot add a bug without a name!', title: 'Error adding bug'})
            setTimeout(() => setNotification({show: false, text: '', title: ''}), 2000)
        }
        else {
            const newBug: Bug = {
                "id": bug?.id || hashCode(new Date().toDateString())+hashCode(name?.value)+"d",
                "name": name?.value,
                "sex": sex?.value,
                "species": species?.value,
                "locale": locale?.value,
                "disperse": disperse?.value,
                "lastFed": lastFed?.value,
                "matured": matured?.value,
                "instar": instar?.value,
                "origin": origin?.value,
                "breeder": breeder?.value,
                "notes": notes?.value,
                "mother": mother?.value,
                "father": father?.value,
                "commonName": commonName?.value,
                "img": selectedFile?.name || ''
            }
            axios.post(BASE_PATH + "/api.php?api&creds=".concat(creds),
                newBug,
            )
                .then(() => {
                    setNotification({show: true, text: 'Added a bug!', title: 'Added bug'})
                    setTimeout(() => {
                            setNotification({show: false, text: '', title: ''})

                            if (setBugs) {
                                const newBugs = bugs?.filter((oldBug: Bug) => oldBug.id !== newBug.id)
                                const editedBug = bugs?.filter((oldBug: Bug) => oldBug.id === newBug.id)
                                if (editedBug && editedBug.length > 0) newBug.img = editedBug[0].img || (selectedFile?.name ? selectedFile?.name : 'assets/icon/icon.png')
                                newBugs?.push(newBug)
                                setBugs(newBugs)
                                setFilteredBugs(newBugs)

                                setSettings({...settings, addingBug: true})
                            }

                            handleBackClick()
                        }, 1500
                    )
                })
                .catch(e => {
                    setNotification({
                        show: true,
                        text: 'Error when adding bug: '.concat(e.data.text),
                        title: 'Error adding bug'
                    })
                    setTimeout(() => setNotification({show: false, text: '', title: ''}), 2000)
                })
        }
    }

    const handleBackClick = () => {
        setCurrentBug && setCurrentBug(null)
        history.push('/collection')
    }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 2</IonTitle>
          </IonToolbar>
        </IonHeader>
          <NotificationPopup notification={notification}/>
          <Row md={12}>
              <Col md={12} style={{margin: '1rem'}}>
                <div className={"mb-2"} style={{cursor: 'pointer', marginTop: '1rem'}} onClick={handleBackClick}><FontAwesomeIcon icon={faArrowLeft} className={"fa-2x"}/></div>
                  <center>
                    <img src={bug?.img? constructImagePath(bug?.img, dataDir) : BASE_PATH + '/app/assets/icon/icon.png'}
                         style={{width: '145px', height: '145px', marginLeft: '-2rem'}}
                         className='rounded-circle'/>
                      <Form.Control
                          type="file"
                          id="img"
                          name="img"
                          style={{width: '70%', marginLeft: '-2rem', marginTop: '1rem'}}
                          onChange={changeHandler}
                      />
                  </center>
              </Col>

              <Col sm={4} style={{margin: '1rem', marginRight: 0, width: '35%'}}>
                  <Form.Label htmlFor="username">Name</Form.Label>
                  <Form.Control
                      type="text"
                      id="name"
                      required
                      placeholder={bug?.name || "Name"}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, name: e.target.value})}
                      value={bug?.name}
                  />

              </Col>
              <Col sm={3} style={{margin: '1rem', marginLeft: '0', width: '25%'}}>
                  <Form.Label htmlFor="username">Sex</Form.Label>
                  <Form.Select
                      id="sex"
                      aria-label="sex"
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, sex: e.target.value})}
                  >
                      <option value="-" selected={bug?.sex === "-"}>-</option>
                      <option value="M" selected={bug?.sex === "M"}>M</option>
                      <option value="F" selected={bug?.sex === "F"}>F</option>
                  </Form.Select>
              </Col>

              <Col sm={3} style={{margin: '1rem', marginLeft: 0, width: '25%'}}>
                  <Form.Label htmlFor="username">Instar</Form.Label>
                  <Form.Control
                      type="text"
                      id="instar"
                      required
                      placeholder={bug?.instar || "0"}
                      value={bug?.instar}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, instar: e.target.value})}
                  />
              </Col>

              <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                  <Form.Label htmlFor="username">Species</Form.Label>
                  <Form.Control
                      type="text"
                      id="species"
                      required
                      placeholder={bug?.species || "Species"}
                      value={bug?.species}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, species: e.target.value})}
                  />
              </Col>
              <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                  <Form.Label htmlFor="username">Locale/Morph</Form.Label>
                  <Form.Control
                      type="text"
                      id="locale"
                      required
                      placeholder={bug?.locale || "Locale"}
                      value={bug?.locale}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, locale: e.target.value})}
                  />
              </Col>
              <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                  <Form.Label htmlFor="username">Common Name</Form.Label>
                  <Form.Control
                      type="text"
                      id="commonName"
                      required
                      placeholder={bug?.commonName || "Common Name"}
                      value={bug?.commonName}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, commonName: e.target.value})}
                  />
              </Col>

              <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                  <Form.Label htmlFor="username">Breeder/seller</Form.Label>
                  <Form.Control
                      type="text"
                      id="breeder"
                      required
                      placeholder={bug?.breeder || "Breeder"}
                      value={bug?.breeder}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, breeder: e.target.value})}
                  />
              </Col>
              <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                  <Form.Label htmlFor="username">Origin</Form.Label>
                  <Form.Select
                      id="origin"
                      aria-label="origin"
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, origin: e.target.value})}
                  >
                      <option value="CB" selected={bug?.origin === "CB"}>CB</option>
                      <option value="WC" selected={bug?.origin === "WC"}>WC</option>
                      <option value="CF" selected={bug?.origin === "CF"}>CF</option>
                  </Form.Select>
              </Col>
              <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                  <Form.Label htmlFor="username">Matured On</Form.Label>
                  <Form.Control
                      type="date"
                      id="matured"
                      required
                      placeholder={bug?.matured || "DD/MM/YYYY"}
                      value={bug?.matured}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, matured: e.target.value})}
                  />
              </Col>

              <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                  <Form.Label htmlFor="username">Last Moult</Form.Label>
                  <Form.Control
                      type="date"
                      id="disperse"
                      required
                      placeholder={bug?.disperse || "DD/MM/YYYY"}
                      value={bug?.disperse}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, disperse: e.target.value})}
                  />
              </Col>

              <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                  <Form.Label htmlFor="username">Last Fed</Form.Label>
                  <Form.Control
                      type="date"
                      id="lastFed"
                      required
                      placeholder={bug?.lastFed || "DD/MM/YYYY"}
                      value={bug?.lastFed}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, lastFed: e.target.value})}
                  />
              </Col>

              <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                  <Form.Label htmlFor="username">Mother</Form.Label>
                  <Form.Control
                      type="text"
                      id="mother"
                      required
                      placeholder={bug?.mother || "Mother"}
                      value={bug?.mother}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, mother: e.target.value})}
                  />
              </Col>
              <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                  <Form.Label htmlFor="username">Father</Form.Label>
                  <Form.Control
                      type="text"
                      id="father"
                      required
                      placeholder={bug?.father || "Father"}
                      value={bug?.father}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, father: e.target.value})}
                  />
              </Col>
              <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                  <Form.Label htmlFor="username">Notes</Form.Label>
                  <Form.Control
                      as={"textarea"}
                      rows={3}
                      id="notes"
                      required
                      placeholder={bug?.notes || "Notes"}
                      value={bug?.notes}
                      onChange={(e) => setCurrentBug && setCurrentBug({...bug, notes: e.target.value})}
                  />
              </Col>

              <Col md={12} style={{margin: '1rem', width: '90%'}}>

                  <div className={"mt-3"}/>
                  <Form.Control
                      type="submit"
                      value={"Save"}
                      onClick={handleSave}
                  />

                  <div className={"mt-3"}/>
                  <Row className={"mx-1"}>
                      <Button
                          variant={"danger"}
                          onClick={handleDelete}
                      >
                          Delete
                      </Button>
                  </Row>
              </Col>
          </Row>
      </IonContent>
    </IonPage>
  );
};

export default AddBug;
