import React, {useEffect} from 'react';
import {
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonAlert
} from '@ionic/react';
import './Collection.css';
import {Badge, Table, Form} from "react-bootstrap";
import {add} from "ionicons/icons";
import axios from "axios";
import {Bug, Bugs, CollectionProps} from "../Types";
import AddBug from "./AddBug";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {BASE_PATH, constructImagePath, handleLogout, setDataToStorage} from "../util/tools";
import {useHistory} from "react-router";

const ONE_YEAR = 365 * 24 * 3600 * 1000
const FOUR_DAYS = 4 * 24 * 3600 * 1000

const Collection: React.FC<CollectionProps> = ({creds: creds, bugs: bugs, setBugs: setBugs, settings: settings, setSettings, filteredBugs: filteredBugs, setFilteredBugs: setFilteredBugs, currentBug: currentBug, setCurrentBug: setCurrentBug, dataDir: dataDir, setDataDir: setDataDir, setPrices, isWebapp, setLogin, login}) => {
    const user = creds.split("_")[0]
    const history = useHistory()

    const [presentAlert] = useIonAlert()
    const openDetailBugPage = (bug: Bug) => {
        setCurrentBug(bug)
    }

    const filterBugs = (e: any) => {
        const filtered = bugs.filter((b: Bug) => {
            const v = e.target.value.toLowerCase()
            return b.name.toLowerCase().includes(v) || b.species.toLowerCase().includes(v) || b.commonName.toLowerCase().includes(v)
        })
        setFilteredBugs(filtered)
    }

    useEffect(() => {
        const transformData = (data: Bugs) => {
            const bugs: Array<Bug> = []
            const names = Object.keys(data)
            names.forEach(id => bugs.push({
                id: id,
                name: data[id]?.name || '',
                sex: data[id]?.sex || 'X',
                species: data[id]?.species || '',
                locale: data[id]?.locale || '',
                disperse: data[id]?.disperse || '',
                lastFed: data[id]?.lastFed || '',
                matured: data[id]?.matured || '',
                instar: data[id]?.instar || '0',
                origin: data[id]?.origin || '',
                breeder: data[id]?.breeder || '',
                notes: data[id]?.notes || '',
                mother: data[id]?.mother || '',
                father: data[id]?.father || '',
                commonName: data[id]?.commonName || '',
                img: data[id]?.img
            }))
            return bugs
        }
        axios.get(BASE_PATH + "/api.php?api&list-bugs&creds=".concat(creds))
            .then(data => {
                if (data.data) {
                    if (data.data.bugs) {
                        const transformedBugs = transformData((data.data.bugs))
                        setBugs(transformedBugs)
                        setFilteredBugs(transformedBugs)
                        setDataToStorage("bugs", creds, transformedBugs)
                    }

                    if (dataDir !== data.data.dataDir && data.data.dataDir) {
                        setDataDir(data.data.datadir)
                        setDataToStorage("dataDir", creds, data.data.dataDir)
                    }

                    const now = Date.now()
                    let lastShownReviewPopup = settings?.lastShownReviewPopup
                    if (!settings?.lastShownReviewPopup || now - settings?.lastShownReviewPopup > ONE_YEAR) {
                        if (!settings?.lastShownReviewPopup) {
                            lastShownReviewPopup = now - ONE_YEAR + FOUR_DAYS
                        }
                        else {
                            lastShownReviewPopup = now
                            presentAlert({
                                header: 'Review',
                                message: 'Would you like to leave a review? Reviews help us to make the app better.',
                                buttons: [{
                                    text: 'OK',
                                    handler: () => {
                                        window.open("https://play.google.com/store/apps/details?id=com.nexarcore.bugbase", "_system")
                                    }
                                }, 'No thanks'],
                            })
                        }
                    }

                    if (settings.upgraded !== data.data.upgraded || settings.maxBugs !== data.data.maxBugs || settings.lastShownReviewPopup !== lastShownReviewPopup) {
                        setSettings({...settings, upgraded: data.data.upgraded, maxBugs: data.data.maxBugs, lastShownReviewPopup: lastShownReviewPopup})
                        setDataToStorage("settings", creds, {...settings, upgraded: data.data.upgraded, maxBugs: data.data.maxBugs, lastShownReviewPopup: lastShownReviewPopup})
                    }


                    if (data.data.token !== null && user + "+++" + data.data.token !== creds) {
                        localStorage.setItem('loginCredentials', user + "+++" + data.data.token)
                    }

                    if (data.data.text?.includes("security token") && data.data.type === "error" && data.data.token === null) {
                        handleLogout(setLogin, login, history)
                    }

                    if (isWebapp) {
                        setPrices({pro: data.data.proPrice, add10bugs: data.data.add10bugs})
                    }
                }
            })
            .catch(e => console.log("No bugs: ", e))

        /*const savedBugs = getDataFromStorage("bugs", creds)
        setBugs(savedBugs)*/

    }, [])
  return !currentBug ? (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Collection</IonTitle>
          </IonToolbar>
        </IonHeader>
          <Form.Control
              type="text"
              id="search"
              required
              placeholder={"Search bug..."}
              autoComplete={"off"}
              onChange={filterBugs}
          />
          <Table bordered hover responsive>
              <thead style={{color: "#34573f !important"}}>
              <tr>
                  <th scope='col'>Name</th>
                  <th scope='col'>Species</th>
                  <th scope='col'>Sex</th>
              </tr>
              </thead>
              <tbody>
              {filteredBugs?.map((bug:Bug) =>
                  (<tr key={bug.name} onClick={() => openDetailBugPage(bug)}>
                      <td>
                          <div className='d-flex align-items-center'>
                              <img
                                  src={bug.img ? constructImagePath(bug.img, dataDir) : BASE_PATH + '/app/assets/icon/icon.png'}
                                  alt=''
                                  style={{width: '45px', height: '45px'}}
                                  className='rounded-circle'
                              />
                              <div className='ms-3'>
                                  <p className='fw-extra-light bug-name mb-1'>{bug.name}</p>
                              </div>
                          </div>
                      </td>
                      <td>
                          <Badge bg={"success"} pill className={"bug-name"}>
                              {bug.species}
                          </Badge>
                      </td>

                      <td>
                          {bug.sex === "M" ? <FontAwesomeIcon icon={icon({name: 'mars'})} /> : (bug.sex === "F" ? <FontAwesomeIcon icon={icon({name: 'venus'})} /> : <FontAwesomeIcon icon={icon({name: "question"})} />)}
                      </td>

                  </tr>))
              }
              </tbody>
          </Table>

          {(!bugs || bugs.length === 0) && (
              <center>
                  <p className="text-muted px-1">No bugs in your collection. <br/>Add a bug by clicking the <b>add</b> button below.</p>
              </center>)}

          {bugs.length < settings.maxBugs || (settings.upgraded && bugs.length < 200)? (
              <IonFab slot="fixed" vertical="bottom" horizontal="end">
                  <IonFabButton onClick={() => setCurrentBug({})}>
                      <IonIcon icon={add}/>
                  </IonFabButton>
              </IonFab>
          ) : (
              <center>
                  <div className={"mb-2 px-2"}>
                    <small className={"text-secondary"}>You have used up all of your free bug spaces in the inventory! Either replace some of your entries or use the settings tab to upgrade your account.</small>
                  </div>
              </center>
          )}

      </IonContent>
    </IonPage>
  ) : (<AddBug bug={currentBug} setCurrentBug={setCurrentBug} bugs={bugs} setBugs={setBugs} creds={creds} settings={settings} setFilteredBugs={setFilteredBugs} dataDir={dataDir} setSettings={setSettings}/>)
};

export default Collection;
