import React from 'react';
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import axios from "axios";
import {Bug, Hatch, Pair, PairProps} from "../Types";
import NotificationPopup from "../components/NotificationPopup";
import {BASE_PATH, constructImagePath, generateHatches, getNumbers, setDataToStorage} from "../util/tools";
import ViewHatch from "./ViewHatch";

const AddPair: React.FC<PairProps> = ({bug: currentBug, setCurrentBug: setCurrentBug, bugs: bugs, setPairs: setPairs, pairs: pairs, creds: creds, setFilteredPairs: setFilteredPairs, dataDir: datadir}) => {
    const history = useHistory()

    const [notification, setNotification] = React.useState({show: false, text: '', title: ''})
    const [currentHatch, setCurrentHatch] = React.useState<Hatch | null>(null)

    const handleDelete = () => {
        const name = document.getElementById("namePair") as HTMLInputElement

        if (!name?.value || name?.value === "") {
            setNotification({show: true, text: 'Cannot delete a pair without a name', title: 'Error deleting pair'})
            setTimeout(() => setNotification({show: false, text: '', title: ''}), 2000)
        } else {
            axios.post(BASE_PATH + "/pairs-api.php?api&delete=".concat(name?.value).concat("&creds=").concat(creds))
                .then(() => {
                    setNotification({show: true, text: 'Deleted your pair :(', title: 'Pair Gone'})
                    setTimeout(() => {
                            setNotification({show: false, text: '', title: ''})

                            if (setPairs) {
                                const newPairs = pairs?.filter((pair: Pair) => pair.name !== name?.value)
                                setPairs(newPairs)
                                setFilteredPairs(newPairs)

                                setDataToStorage("pairs", creds, newPairs)
                            }
                            handleBackClick()
                        }, 1500
                    )
                })
                .catch(e => console.log("wrong", e))
        }
    }
    const handleSave = () => {
        const name = document.getElementById("namePair") as HTMLInputElement
        const parentMale = document.getElementById("parentMale") as HTMLInputElement
        const parentFemale = document.getElementById("parentFemale") as HTMLInputElement
        const history = document.getElementById("historyPair") as HTMLInputElement
        const notes = document.getElementById("notesPair") as HTMLInputElement
        const localeMother = document.getElementById("localeMother") as HTMLInputElement
        const localeFather = document.getElementById("localeFather") as HTMLInputElement
        const speciesMale = document.getElementById("speciesPairMale") as HTMLInputElement
        const speciesFemale = document.getElementById("speciesPairFemale") as HTMLInputElement

        const pairingDates = getNumbers(3).map((n: number) => (document.getElementById("pairingDate" + n) as HTMLInputElement)?.value)
        const layingDates = getNumbers(12).map((n: number) => (document.getElementById("layingDate" + n) as HTMLInputElement)?.value)

        if (!name?.value || name?.value === "") {
            setNotification({show: true, text: 'Cannot add a pair without a name!', title: 'Error adding pair'})
            setTimeout(() => setNotification({show: false, text: '', title: ''}), 2000)
        } else {
            const bug: Pair = {
                "name": name?.value,
                "speciesMale": speciesMale?.value,
                "speciesFemale": speciesFemale?.value,
                "parent-male": parentMale?.value,
                "parent-female": parentFemale?.value,
                "history": history?.value,
                "notes": notes?.value,
                "localeMother": localeMother?.value,
                "localeFather": localeFather?.value,
                "pairingDates": pairingDates,
                "layingDates": layingDates,
                "hatches": currentBug?.hatches || generateHatches(12, momBugImage || '', dadBugImage || '')
            }
            axios.post(BASE_PATH + "/pairs-api.php?api&creds=".concat(creds),
                bug,
            )
                .then(() => {
                    setNotification({show: true, text: 'Added a pair!', title: 'Pair added'})
                    setTimeout(() => {
                            setNotification({show: false, text: '', title: ''})

                            if (setPairs) {
                                const newPairs = pairs?.filter((oldPair: Pair) => oldPair.name !== bug.name)
                                newPairs?.push(bug)
                                setPairs(newPairs)
                                setFilteredPairs(newPairs)

                                setDataToStorage("pairs", creds, newPairs)
                            }

                            handleBackClick()
                        }, 1500
                    )
                })
                .catch(e => {
                    setNotification({
                        show: true,
                        text: 'Error when adding pair: '.concat(e.data.text),
                        title: 'Error adding pair'
                    })
                    setTimeout(() => setNotification({show: false, text: '', title: ''}), 2000)
                })
        }
    }

    const handleBackClick = () => {
        setCurrentBug && setCurrentBug(null)
        setCurrentHatch(null)
        history.push('/breed')
    }

    const momBugImgArr = bugs && currentBug && bugs.filter((b: Bug) => b.id === currentBug["parent-female"])
    const dadBugImgArr = bugs && currentBug && bugs.filter((b: Bug) => b.id === currentBug["parent-male"])
    const momBugImage = momBugImgArr && momBugImgArr.length > 0 && momBugImgArr[0].img
    const dadBugImage = dadBugImgArr && dadBugImgArr.length > 0 && dadBugImgArr[0].img

    return (
        <IonPage>
            {!currentHatch && (


                <IonContent fullscreen>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">Tab 2</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <NotificationPopup notification={notification}/>
                    <Row md={12}>
                        <Col md={12} style={{margin: '1rem'}}>
                            <div className={"mb-2"} style={{cursor: 'pointer', marginTop: '1rem'}}
                                 onClick={handleBackClick}><FontAwesomeIcon icon={faArrowLeft} className={"fa-2x"}/></div>

                        </Col>

                        <Col sm={12} style={{margin: '1rem', marginTop: '.3rem', marginBottom: '2.7rem', width: '90%'}}>
                            <Form.Label htmlFor="username">Name</Form.Label>
                            <Form.Control
                                type="text"
                                id="namePair"
                                required
                                placeholder={currentBug?.name || "Name"}
                                onChange={(e) => setCurrentBug && setCurrentBug({...currentBug, name: e.target.value})}
                                value={currentBug?.name}
                            />

                        </Col>

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <center>
                                <img src={momBugImage ? constructImagePath(momBugImage, datadir) : BASE_PATH + '/app/assets/icon/icon.png'}
                                     style={{width: '145px', height: '145px', marginLeft: '-.4rem', marginTop: '0rem'}}
                                     className='rounded-circle'/>
                                <img src={dadBugImage ? constructImagePath(dadBugImage, datadir) : BASE_PATH + '/app/assets/icon/icon.png'}
                                     style={{width: '145px', height: '145px', marginLeft: '1.9rem', marginTop: '0rem'}}
                                     className='rounded-circle'/>
                            </center>
                        </div>

                        <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                            <Form.Label htmlFor="username">Mother</Form.Label>
                            <Form.Select
                                id="parentFemale"
                                aria-label="parentFemale"
                                onChange={(e) => setCurrentBug && setCurrentBug({...currentBug, "parent-female": e.target.value})}
                            >
                                <option selected={"Unknown" === currentBug?.["parent-female"]} value={"Unknown"}>Unknown</option>
                                <option selected={"Not Owned" === currentBug?.["parent-female"]} value={"Not Owned"}>Not Owned</option>
                                {bugs?.map((mybug: Bug) => <option key={mybug.id} selected={mybug.id === currentBug?.["parent-female"]} value={mybug.id}>{mybug.name}</option>)}
                            </Form.Select>
                        </Col>
                        <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                            <Form.Label htmlFor="username">Father</Form.Label>
                            <Form.Select
                                id="parentMale"
                                aria-label="parentMale"
                                onChange={(e) => setCurrentBug && setCurrentBug({...currentBug, "parent-male": e.target.value})}
                            >
                                <option selected={"Unknown" === currentBug?.["parent-male"]} value={"Unknown"}>Unknown</option>
                                <option selected={"Not Owned" === currentBug?.["parent-male"]} value={"Not Owned"}>Not Owned</option>
                                {bugs?.map((mybug: Bug) => <option key={mybug.id} selected={mybug.id === currentBug?.["parent-male"]} value={mybug.id}>{mybug.name}</option>)}
                            </Form.Select>
                        </Col>

                        <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                            <Form.Label htmlFor="username">Species</Form.Label>
                            <Form.Control
                                type="text"
                                id="speciesPairFemale"
                                required
                                placeholder={currentBug?.speciesFemale || "Species"}
                                value={currentBug?.speciesFemale || bugs?.filter((b: Bug) => b.id === currentBug?.["parent-female"])?.at(0)?.species}
                                onChange={(e) => setCurrentBug && setCurrentBug({...currentBug, speciesFemale: e.target.value})}
                            />
                        </Col>
                        <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                            <Form.Label htmlFor="username">Species</Form.Label>
                            <Form.Control
                                type="text"
                                id="speciesPairMale"
                                required
                                placeholder={currentBug?.speciesMale || "Species"}
                                value={currentBug?.speciesMale || bugs?.filter((b: Bug) => b.id === currentBug?.["parent-male"])?.at(0)?.species}
                                onChange={(e) => setCurrentBug && setCurrentBug({...currentBug, speciesMale: e.target.value})}
                            />
                        </Col>

                        <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                            <Form.Label htmlFor="username">Locale/Morph</Form.Label>
                            <Form.Control
                                type="text"
                                id="localeMother"
                                required
                                placeholder={currentBug?.localeMother || "Locale"}
                                value={currentBug?.localeMother || bugs?.filter((b: Bug) => b.id === currentBug?.["parent-female"])?.at(0)?.locale}
                                onChange={(e) => setCurrentBug && setCurrentBug({...currentBug, localeMother: e.target.value})}
                            />
                        </Col>
                        <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                            <Form.Label htmlFor="username">Locale/Morph</Form.Label>
                            <Form.Control
                                type="text"
                                id="localeFather"
                                required
                                placeholder={currentBug?.localeFather || "Locale"}
                                value={currentBug?.localeFather || bugs?.filter((b: Bug) => b.id === currentBug?.["parent-male"])?.at(0)?.locale}
                                onChange={(e) => setCurrentBug && setCurrentBug({...currentBug, localeFather: e.target.value})}
                            />
                        </Col>

                        <Col sm={12} style={{margin: '1rem'}}>
                            <Form.Label htmlFor="username">Pairing Dates</Form.Label>
                            <div style={{display: 'flex'}}>
                                {getNumbers(3).map((n: number) => (
                                    <div style={{width: '30%'}} key={"pairingDate" + n}>
                                        <Form.Control
                                            type="date"
                                            id={"pairingDate" + n}
                                            required
                                            placeholder={currentBug?.pairingDates[n] || "DD/MM/YYYY"}
                                            value={currentBug?.pairingDates[n]}
                                            onChange={(e) => {
                                                if (setCurrentBug) {
                                                    const pairingDates = currentBug?.pairingDates
                                                    if (pairingDates) {
                                                        pairingDates[n] = e.target.value
                                                        setCurrentBug({...currentBug, pairingDates: pairingDates})
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Col>
                        <Col sm={12} style={{margin: '1rem'}}>
                            <Form.Label htmlFor="username">Laying Dates</Form.Label>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                {getNumbers(12).map((n: number) => (
                                    <div style={{width: '30%', marginBottom: '1rem'}} key={"layingDate" + n}>
                                        <Form.Control
                                            type="date"
                                            id={"layingDate" + n}
                                            required
                                            placeholder={currentBug?.layingDates[n] || "DD/MM/YYYY"}
                                            value={currentBug?.layingDates[n]}
                                            onChange={(e) => {
                                                if (setCurrentBug) {
                                                    const layingDates = currentBug?.layingDates
                                                    if (layingDates) {
                                                        layingDates[n] = e.target.value
                                                        setCurrentBug({...currentBug, layingDates: layingDates})
                                                    }
                                                }
                                            }}
                                        />
                                        <Badge pill bg={"success"} style={{cursor: 'pointer'}} onClick={() => {
                                            const currentHatch = currentBug?.hatches[n] || generateHatches(1, momBugImage || '', dadBugImage || '')[0]
                                            currentHatch.pairName = currentBug?.name || ''
                                            currentHatch.motherImg = momBugImage || ''
                                            currentHatch.fatherImg = dadBugImage || ''
                                            currentHatch.hatchNumber = n
                                            setCurrentHatch(currentHatch)
                                        }}>view hatch</Badge>
                                    </div>
                                ))}
                            </div>
                        </Col>

                        <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                            <Form.Label htmlFor="username">Notes</Form.Label>
                            <Form.Control
                                as={"textarea"}
                                rows={3}
                                id="notesPair"
                                required
                                placeholder={currentBug?.notes || "Notes"}
                                value={currentBug?.notes}
                                onChange={(e) => setCurrentBug && setCurrentBug({...currentBug, notes: e.target.value})}
                            />
                        </Col>

                        <Col md={12} style={{margin: '1rem', width: '90%'}}>

                            <div className={"mt-3"}/>
                            <Form.Control
                                type="submit"
                                value={"Save"}
                                onClick={handleSave}
                            />

                            <div className={"mt-3"}/>
                            <Row className={"mx-1"}>
                                <Button
                                    variant={"danger"}
                                    onClick={handleDelete}
                                >
                                    Delete
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </IonContent>
            )}

            {currentHatch && (
                <IonContent fullscreen>
                    <ViewHatch hatch={currentHatch} creds={creds} setCurrentHatch={setCurrentHatch} setPairs={setPairs} dataDir={datadir} pair={currentBug} setPair={setCurrentBug}/>
                </IonContent>
            )}
        </IonPage>
    );
};

export default AddPair;
