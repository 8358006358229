import React, {useEffect} from 'react';
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import './Timetable.css';
import {Calendar} from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import {DayPage} from "./DayPage";
import {DayType, DayTypes, TimetableProps} from "../Types";
import {BASE_PATH, generateDayType} from "../util/tools";
import axios from "axios";
import {Col, Row} from "react-bootstrap";

const Timetable: React.FC<TimetableProps> = ({creds}) => {
    const [currentDay, setCurrentDay] = React.useState<DayType>()
    const [days, setDays] = React.useState<Array<DayType>>([])

    const getDayByDate = (d: string) => {
        return days?.filter((myday: DayType) => myday.date?.toDateString() === d).at(0)
    }

    useEffect(() => {
        const transformData = (data: DayTypes) => {
            const days: Array<DayType> = []
            const names = Object.keys(data)
            names.forEach(name => days.push({
                date: new Date(name),
                watered: data[name]?.watered,
                fed: data[name]?.fed,
                cleaned: data[name]?.cleaned,
                notes: data[name]?.notes || '',
            }))
            return days
        }
        axios.get(BASE_PATH + "/day-api.php?api&list&creds=".concat(creds))
            .then(data => {
                if (data.data) {
                    const transformedDays = transformData(data.data)
                    setDays(transformedDays)
                }
            })
            .catch(e => console.log("No days: ", e))
    }, [])

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Tab 2</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <Row sm={12}>
                    <center>
                        <Col sm={12} md={6}>

                        {currentDay ? <DayPage currentDay={currentDay} setCurrentDay={setCurrentDay} creds={creds} days={days} setDays={setDays}/> :
                        <>
                        <Calendar
                            className={['c1', 'c2']}
                            onClickDay={(e) => setCurrentDay(getDayByDate(e.toDateString()) || generateDayType(e))}
                            formatDay={(locale, date) => {
                                const dateInfo = getDayByDate(date.toDateString())
                                let d = date.getDate() + ""
                                if (dateInfo?.watered)
                                    d +=  " • "
                                if (dateInfo?.fed)
                                    d +=  " • "
                                if (dateInfo?.cleaned)
                                    d +=  " • "
                                return d
                            }}
                            tileClassName={({ date }) => {
                                const dateInfo = getDayByDate(date.toDateString())
                                let o = ''
                                let combo = 0
                                if(dateInfo?.watered){
                                    o += 'watered '
                                    combo++
                                }
                                if(dateInfo?.fed){
                                    o += 'fed '
                                    combo++
                                }
                                if(dateInfo?.cleaned){
                                    o += "cleaned"
                                    combo++
                                }
                                if (combo > 1) return "combo"
                                return o
                            }}
                        />
                            <DayPage currentDay={getDayByDate(new Date().toDateString()) || generateDayType(new Date())} setCurrentDay={null} creds={creds} days={days} setDays={setDays}/>
                        </>
                        }
                        </Col>
                    </center>
                </Row>
            </IonContent>
        </IonPage>
    );
};

export default Timetable;
