import React from 'react';
import {
    IonCard,
    IonContent,
    IonItem,
    IonList,
    IonPage,
    IonTitle,
    IonToggle,
    isPlatform,
    useIonAlert
} from '@ionic/react';
import {Col, Row} from "react-bootstrap";
import {SettingsProps} from "../Types";
import {BASE_PATH, donateSentences, setDataToStorage} from "../util/tools";
import {IAPProduct, InAppPurchase2 as iap} from "@ionic-native/in-app-purchase-2";
import axios from "axios";
import {UpgradeInventoryButton} from "../components/UpgradeInventoryButton";

const Settings: React.FC<SettingsProps> = ({settings: settings, setSettings: setSettings, creds: creds, product, productPrices, isWebapp}) => {
    const [presentAlert] = useIonAlert()

    const syncSettings = (orderId: any, payer: string, upgrade: boolean) => {
        const randomIntegrity = btoa(Math.random() + creds + Math.random()).substring(0, 40)
        setSettings({...settings, upgraded: upgrade, maxBugs: settings.maxBugs + 10})
        setDataToStorage("settings", creds, {...settings, upgraded: upgrade})
        axios.post(BASE_PATH + "/api.php?api".concat("&creds=").concat(creds), {upgraded: upgrade, orderID: orderId, name: payer, maxBugs: settings.maxBugs + 10, integrity: randomIntegrity})
        presentAlert({
            header: 'Upgraded',
            message: upgrade ? 'Account upgraded. Enjoy 200 bug spaces!' : 'Added 10 more bug spaces.',
            buttons: ['OK'],
        })
    }

    const purchaseProduct = (productId: string, upgrade: boolean) => {
        if (product.owned) {
            alert('Product already owned!')
        } else {
            if ((isPlatform('ios')) || (isPlatform('android'))) {
                iap.order(productId).then(() => {
                    iap.when(productId).approved((p: IAPProduct) => {
                        //store product

                        syncSettings(null, "me", upgrade)

                        p.verify();
                        p.finish();

                    });
                })
                iap.refresh();
            }
            else {
                presentAlert({
                    header: 'Upgrade',
                    message: 'Feature will be implemented in future updates.',
                    buttons: ['OK'],
                })
            }
        }
    }

    /*const restore = () => {
        iap.when("pro_sub").owned(() => {
            if (product.owned) {
                //store product
                setSettings({...settings, upgraded: false})
                setDataToStorage("settings", creds, {...settings, upgraded: false})
            } else {
                alert("You have not purchased this product before.")
            }
        });
        iap.refresh();
    }*/

    return (
        <IonPage>
            <IonContent fullscreen>
                <Row sm={12} style={{margin: '1rem'}}/>
                <Row sm={12}>
                    <center>
                    <Col sm={12} md={6}>
                        <IonTitle size="large">Settings</IonTitle>
                        <IonList style={{marginTop: '1rem'}}>
                            <IonItem>
                                <IonToggle checked={true} disabled={true}>Sync Account</IonToggle>
                            </IonItem>


                            <IonCard className="homeCards">

                                    <UpgradeInventoryButton productPrices={productPrices} purchaseProduct={purchaseProduct} syncSettings={syncSettings} isWebapp={isWebapp} settings={settings}/>
                                    <>
                                        {settings && settings.upgraded && (
                                            <IonItem>
                                                <IonToggle checked={settings && settings.upgraded} disabled={true}>Inventory Upgraded</IonToggle>
                                            </IonItem>
                                        )}

                                        <div style={{margin: '1.5rem'}}>
                                            <small className={"text-secondary"}>Current Access: {settings?.upgraded ? "200" : settings.maxBugs} bugs</small>
                                        </div>
                                    </>

                            </IonCard>

                            <IonItem>
                                <div style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '1rem'}}>
                                    <p className="text-muted px-1">{donateSentences[Math.round(Math.random() * (donateSentences.length -1))]}</p>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                    <form action="https://www.paypal.com/donate" method="post" target="_top">
                                        <input type="hidden" name="hosted_button_id" value="CDCJFAQZR4MGU" />
                                        <input type="image" src="https://pics.paypal.com/00/s/YTYyNzRmODYtMWFiZi00NTNiLTlhNWMtMjFjYzJmNWY0NGM4/file.PNG"
                                               name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" style={{width: 93, height: 32}}/>
                                        <img alt="" src="https://www.paypal.com/en_BE/i/scr/pixel.gif" width="1" height="1" />
                                    </form>
                                </div>
                            </IonItem>

                            <div style={{opacity: '0'}} id="hiddenMovingBugs" onClick={() => (document.getElementById("hiddenMovingBugs") as HTMLElement).style.opacity = '1'}>
                                <h6 style={{margin: '1rem'}}>Hidden Settings</h6>
                                <IonItem>
                                    <IonToggle checked={!(settings && settings.seenMovingBugs)} onClick={() => {
                                        setSettings({...settings, seenMovingBugs: !settings.seenMovingBugs})
                                        setDataToStorage("settings", creds, {...settings, seenMovingBugs: !settings.seenMovingBugs})
                                    }}>Move bugs around</IonToggle>
                                </IonItem>
                            </div>
                        </IonList>
                    </Col>
                    </center>
                </Row>
            </IonContent>
        </IonPage>
    );
}

/*
<IonItem>
    <IonToggle checked={settings && settings.upgraded}>Upgrade Account</IonToggle>
</IonItem>

<IonButton onClick={restore}>Restore</IonButton>

Cancel subscription in the subscription section of the Play Store
 */

export default Settings
