import React from 'react';
import {useHistory} from 'react-router-dom';
import {
    IonButtons,
    IonContent,
    IonHeader, IonLabel,
    IonMenu,
    IonMenuButton,
    IonPage,
    IonTabButton,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Col, Row} from "react-bootstrap";
import {handleLogout} from "../util/tools";
const Menu = ({content, setLogin, login}: any) => {
    const history = useHistory()

    return (
        <>
            <IonMenu contentId="main-content">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle><div className={"app-title"}>BUG BASE MENU</div></IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">

                    <Row md={12}>
                        <Col md={12} className={"mt-2 mb-4"}>
                            <div className={"d-flex"} style={{marginLeft: '.5rem'}}>
                                <IonMenuButton className={"menu-button"}>
                                    <IonTabButton tab="collection" onClick={() => history.push("/collection")}>
                                        <FontAwesomeIcon icon={icon({name: 'bug'})} />
                                        <IonLabel>Collection</IonLabel>
                                    </IonTabButton>
                                </IonMenuButton>
                            </div>
                            <hr/>
                        </Col>
                        <Col md={12} className={"mt-2 mb-4"}>
                            <div className={"d-flex"} style={{marginLeft: '1.2rem'}}>
                                <IonMenuButton className={"menu-button"}>
                                    <IonTabButton tab="breed" onClick={() => history.push("/breed")}>
                                        <FontAwesomeIcon icon={icon({name: 'seedling'})} />
                                        <IonLabel>Breed</IonLabel>
                                    </IonTabButton>
                                </IonMenuButton>
                            </div>
                            <hr/>
                        </Col>
                        <Col md={12} className={"mt-2 mb-4"}>
                            <div className={"d-flex"} style={{marginLeft: '0.5rem'}}>
                                <IonMenuButton className={"menu-button"}>
                                    <IonTabButton tab="timetable" onClick={() => history.push("/timetable")}>
                                        <FontAwesomeIcon icon={icon({name: 'calendar'})} />
                                        <IonLabel>Timetable</IonLabel>
                                    </IonTabButton>
                                </IonMenuButton>
                            </div>
                            <hr/>
                        </Col>
                        <Col md={12} className={"mt-2 mb-4"}>
                            <div className={"d-flex"} style={{marginLeft: '.85rem'}}>
                                <IonMenuButton className={"menu-button"}>
                                    <IonTabButton tab="settings" onClick={() => history.push("/settings")}>
                                        <FontAwesomeIcon icon={icon({name: 'cog'})} />
                                        <IonLabel>Settings</IonLabel>
                                    </IonTabButton>
                                </IonMenuButton>
                            </div>
                            <hr/>
                        </Col>
                        <Col md={12} className={"mt-2 mb-4"}>
                            <div className={"d-flex"} style={{marginLeft: '1.1rem'}} onClick={() => handleLogout(setLogin, login, history)}>
                                <IonMenuButton className={"menu-button"}>
                                    <IonTabButton tab="logout">
                                        <FontAwesomeIcon icon={icon({name: 'sign-out'})} />
                                        <IonLabel>Logout</IonLabel>
                                    </IonTabButton>
                                </IonMenuButton>
                            </div>
                            <hr/>
                        </Col>
                    </Row>
                </IonContent>
            </IonMenu>
            <IonPage id="main-content">
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton></IonMenuButton>
                        </IonButtons>
                        <IonTitle><div className={"app-title"}>BUG BASE</div></IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">{content}</IonContent>
            </IonPage>
        </>
    );
}
export default Menu;