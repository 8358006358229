import React, {useEffect} from 'react';
import {IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import './Collection.css';
import {Badge, Table, Form} from "react-bootstrap";
import {add} from "ionicons/icons";
import axios from "axios";
import {Bug, BugPairProps, Pair, Pairs} from "../Types";
import {BASE_PATH, generatePair} from "../util/tools";
import AddPair from "./AddPair";

const BugPairs: React.FC<BugPairProps> = ({creds: creds, bugs: bugs, pairs: pairs, setPairs: setPairs, filteredPairs: filteredPairs, setFilteredPairs: setFilteredPairs, dataDir: dataDir}) => {
    const [currentBug, setCurrentBug] = React.useState<Pair>()

    const openDetailBugPage = (bug: Pair) => {
        setCurrentBug(bug)
    }

    const filterPairs = (e: any) => {
        const filtered = pairs.filter((b: Pair) => {
            const v = e.target.value.toLowerCase()
            return b.name.toLowerCase().includes(v) || b["parent-male"].toLowerCase().includes(v) || b["parent-female"].toLowerCase().includes(v)
        })
        setFilteredPairs(filtered)
    }

    useEffect(() => {


        const transformData = (data: Pairs) => {
            const pairs: Array<Pair> = []
            const names = Object.keys(data)
            names.forEach(name => {
                pairs.push({
                    name: name,
                    "parent-male": data[name]["parent-male"] || '',
                    "parent-female": data[name]["parent-female"] || '',
                    history: data[name]?.history || '',
                    notes: data[name]?.notes || '',
                    localeMother: data[name]?.localeMother || '',
                    localeFather: data[name]?.localeFather|| '',
                    pairingDates: data[name]?.pairingDates || [],
                    layingDates: data[name]?.layingDates || [],
                    hatches: data[name]?.hatches || [],
                    speciesMale: data[name]?.speciesMale || '',
                    speciesFemale: data[name]?.speciesFemale || ''
                })
            })
            return pairs
        }
        axios.get(BASE_PATH + "/pairs-api.php?api&list-pairs&creds=".concat(creds))
            .then(data => {
                if (data.data) {
                    const transformedData = transformData(data.data)
                    setPairs(transformedData)
                    setFilteredPairs(transformedData)
                }
            })
            .catch(e => console.log("No bugs: ", e))
    }, [])
    return !currentBug ? (
        <IonPage>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Collection</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <Form.Control
                    type="text"
                    id="search"
                    required
                    placeholder={"Search pair..."}
                    autoComplete={"off"}
                    onChange={filterPairs}
                />
                <Table bordered hover responsive>
                    <thead style={{color: "#34573f !important;"}}>
                    <tr>
                        <th scope='col'>Name</th>
                        <th scope='col'>Mother</th>
                        <th scope='col'>Father</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredPairs?.map((bug: Pair) =>
                        (<tr key={bug.name} onClick={() => openDetailBugPage(bug)}>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <div className='ms-3'>
                                        <p className='fw-extra-light bug-name mb-1'>{bug.name}</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <Badge bg={"success"} pill className={"bug-name"}>
                                    {bugs.filter((b: Bug) => b.id === bug["parent-female"])?.at(0)?.name}
                                </Badge>
                            </td>
                            <td>
                                <Badge bg={"success"} pill className={"bug-name"}>
                                    {bugs.filter((b: Bug) => b.id === bug["parent-male"])?.at(0)?.name}
                                </Badge>
                            </td>
                        </tr>))
                    }
                    </tbody>
                </Table>

                {(!pairs || pairs.length === 0) && (
                    <center>
                        <p className="text-muted px-1">No pairs here. <br/>Add a pair by clicking the <b>add</b> button
                            below.</p>
                    </center>)}

                <IonFab slot="fixed" vertical="bottom" horizontal="end">
                    <IonFabButton onClick={() => {
                        setCurrentBug(generatePair())}}>
                        <IonIcon icon={add}/>
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    ) : (<AddPair bug={currentBug} setCurrentBug={setCurrentBug} bugs={bugs} setPairs={setPairs} creds={creds} pairs={pairs} setFilteredPairs={setFilteredPairs} dataDir={dataDir}/>)
};

export default BugPairs;
