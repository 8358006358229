import * as React from "react";
import {Row} from "react-bootstrap";
import {NotificationObject} from "../Types";

const NotificationPopup = ({notification}:NotificationObject) => {
    return (
        <>
            {notification.show && (
                <Row className={"justify-content-center mt-2"}>
                    <div className={"notification-popup"}>
                        <b>{notification.title}</b><br/>
                        {notification.text}
                    </div>
                </Row>
            )}
        </>
    )
}

export default NotificationPopup