import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {HatchProps, Pair} from "../Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {BASE_PATH, constructImagePath, getDataFromStorage, setDataToStorage} from "../util/tools";
import axios from "axios";
import NotificationPopup from "../components/NotificationPopup";

const ViewHatch: React.FC<HatchProps> = ({hatch: originalHatch, creds: creds, setCurrentHatch: setCurrentHatch, setPairs: setPairs, dataDir: datadir, pair, setPair}) => {
    const [notification, setNotification] = React.useState({show: false, text: '', title: ''})
    const [hatch, setHatch] = React.useState(originalHatch)

    const handleSave = () => {
        const pairs = getDataFromStorage("pairs", creds) as Pair[]
        const oldPairs = pairs.filter((p: Pair) => p.name === hatch.pairName)
        if (oldPairs) {
            const newHatch = {
                layDate: hatch.layDate,
                disperseDate: hatch.disperseDate,
                locale: hatch.locale,
                motherImg: hatch.motherImg,
                fatherImg: hatch.fatherImg,
                notes: hatch.notes,
                offspringNumber: hatch.offspringNumber,
                pairName: hatch.pairName,
                hatchNumber: originalHatch.hatchNumber

            }
            const newPair = oldPairs.at(0)
            if (newPair) {
                newPair.hatches[Number(originalHatch.hatchNumber)] = newHatch
                setPair({...pair, hatches: newPair.hatches})
                const newPairs = pairs.filter((p: Pair) => p.name !== originalHatch.pairName)
                newPairs.push(newPair)
                if (setPairs) setPairs(newPairs)
                setDataToStorage("pairs", creds, newPairs)
            }

            axios.post(BASE_PATH + "/pairs-api.php?api&creds=".concat(creds),
                newPair,
            )
                .then(() => {
                    setNotification({show: true, text: 'Saved hatch!', title: 'Hatch added'})
                    setTimeout(() => {
                            setNotification({show: false, text: '', title: ''})
                            setCurrentHatch(null)
                        }, 1500
                    )
                })
                .catch(e => {
                    setNotification({
                        show: true,
                        text: 'Error when adding hatch: '.concat(e.data.text),
                        title: 'Error adding hatch'
                    })
                    setTimeout(() => setNotification({show: false, text: '', title: ''}), 2000)
                })
        }
    }

    return (
        <>
                <NotificationPopup notification={notification}/>
                <Row sm={12}>
                    <Col md={12} style={{margin: '1rem'}}>
                        <div className={"mb-2"} style={{cursor: 'pointer', marginTop: '1rem'}}
                             onClick={() => {
                                 setCurrentHatch(null)
                             }}><FontAwesomeIcon icon={faArrowLeft}
                                                                                     className={"fa-2x"}/></div>

                    </Col>

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <center>
                            <img
                                src={hatch?.motherImg ? constructImagePath(hatch.motherImg, datadir) : BASE_PATH + '/app/assets/icon/icon.png'}
                                style={{width: '145px', height: '145px', marginLeft: '-1rem', marginTop: '-3rem'}}
                                className='rounded-circle'/>
                            <img
                                src={hatch?.fatherImg ? constructImagePath(hatch.fatherImg, datadir) : BASE_PATH + '/app/assets/icon/icon.png'}
                                style={{width: '145px', height: '145px', marginLeft: '-1rem', marginTop: '2rem'}}
                                className='rounded-circle'/>
                        </center>
                    </div>

                    <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                        <Form.Label htmlFor="username">Lay Date</Form.Label>
                        <Form.Control
                            type="date"
                            id={"layDate"}
                            required
                            placeholder={"DD/MM/YYYY"}
                            value={hatch?.layDate}
                            onChange={(e) => setHatch({...hatch, layDate: e.target.value})}
                        />
                    </Col>
                    <Col sm={6} style={{margin: '1rem', width: '40%'}}>
                        <Form.Label htmlFor="username">Disperse/Hatch</Form.Label>
                        <Form.Control
                            type="date"
                            id={"disperseDate"}
                            required
                            placeholder={"DD/MM/YYYY"}
                            value={hatch?.disperseDate}
                            onChange={(e) => setHatch({...hatch, disperseDate: e.target.value})}
                        />
                    </Col>

                    <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                        <Form.Label htmlFor="username">Locale/morph</Form.Label>
                        <Form.Control
                            type="text"
                            id={"locale"}
                            required
                            placeholder={"Locale"}
                            value={hatch?.locale}
                            onChange={(e) => setHatch({...hatch, locale: e.target.value})}
                        />
                    </Col>
                    <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                        <div style={{flexWrap: 'wrap', display: 'flex'}}>
                            <Form.Label htmlFor="username" style={{marginTop: '.5rem'}}>Number of offspring</Form.Label>
                            <Form.Control
                                style={{width: '6rem', marginLeft: '3.5rem'}}
                                type="text"
                                id={"offspringNumber"}
                                required
                                placeholder={"0"}
                                value={hatch?.offspringNumber}
                                onChange={(e) => setHatch({...hatch, offspringNumber: e.target.value})}
                            />
                        </div>
                    </Col>
                    <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                        <Form.Label htmlFor="username">Notes</Form.Label>
                        <Form.Control
                            type="text"
                            id={"notes"}
                            required
                            placeholder={"Notes"}
                            value={hatch?.notes}
                            onChange={(e) => setHatch({...hatch, notes: e.target.value})}
                        />
                    </Col>

                    <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                        <div className={"mt-3"}/>
                        <Form.Control
                            type="submit"
                            value={"Save"}
                            onClick={handleSave}
                        />
                    </Col>
                </Row>
            </>
    )
}

export default ViewHatch