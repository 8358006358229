import React from "react";
import {IonButton, IonLabel} from "@ionic/react";
import {UpgradeInventoryProps} from "../Types";
import {PayPalButton} from "react-paypal-button-v2";
import {ADD10BUGS_PRODUCT_ID, PRO_PRODUCT_ID} from "../util/tools";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";

const upgradetext = "Introductory offer: Unlock almost unlimited pet tracking potential with a small, one-time fee! Increase your Bug Base spaces to 200. Your support enhances your own experience and contributes to the maintenance and running of this ad-free app."
const upgradetextPerBugs = "Add 10 more bugs to upgrade your inventory."

export const UpgradeInventoryButton: React.FC<UpgradeInventoryProps> = ({purchaseProduct, productPrices, syncSettings, isWebapp, settings}) => {
    const [showPaypalButtons, setShowPaypalButtons] = React.useState(false)
    const [proSelected, setProSelected] = React.useState(false)

    return !isWebapp ?
        <>
            <div style={{margin: '1rem'}}>
                <small className={"text-secondary"}>{upgradetextPerBugs}</small>
            </div>
            <IonButton onClick={() => purchaseProduct(ADD10BUGS_PRODUCT_ID, false)} style={{marginBottom: '1rem'}}>{productPrices.add10bugs} &nbsp;&nbsp; <FontAwesomeIcon icon={icon({name: 'shopping-cart'})} /></IonButton>
            <hr/>

            {!settings.upgraded && (
                <>
                    <div style={{margin: '1rem'}}>
                        <small className={"text-secondary"}>{upgradetext}</small>
                    </div>
                    <IonButton onClick={() => purchaseProduct(PRO_PRODUCT_ID, true)} style={{marginBottom: '1rem'}}>Upgrade Inventory for {productPrices.pro}</IonButton>
                    <hr/>
                </>
            )}
        </>
        :
        <>
            {!showPaypalButtons ? (
                <>
                    <div style={{margin: '1rem'}}>
                        <small className={"text-secondary"}>{upgradetextPerBugs}</small>
                    </div>
                    <IonButton onClick={() => {
                        setProSelected(false)
                        setShowPaypalButtons(!showPaypalButtons)
                    }} style={{marginBottom: '1rem'}}>{productPrices.add10bugs} &nbsp;&nbsp; <FontAwesomeIcon icon={icon({name: 'shopping-cart'})} /></IonButton>
                    <hr/>

                    {!settings.upgraded && (
                        <>
                            <div style={{margin: '1rem'}}>
                                <small className={"text-secondary"}>{upgradetext}</small>
                            </div>
                            <IonButton onClick={() => {
                                setProSelected(true)
                                setShowPaypalButtons(!showPaypalButtons)
                            }} style={{marginBottom: '1rem'}}>Upgrade Inventory for {productPrices.pro}</IonButton>
                            <hr/>
                        </>
                    )}
                </>) :
                (
                    <>
                        <div style={{marginTop: '2rem', marginBottom: '2rem'}}>
                        <IonLabel>Upgrade Inventory for {proSelected ? productPrices.pro : productPrices.add10bugs}</IonLabel>
                        </div>
                        <PayPalButton
                            amount={(proSelected ? productPrices.pro : productPrices.add10bugs).replace(/[^0-9.]/g, "")}
                            shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                            onSuccess={(details, data) => {
                                syncSettings(data.orderID, details.payer.name.given_name, proSelected)
                            }}

                            options={{
                                clientId: "AeVPfNVsLEsbrAuQLA2fvADjx8N8yhedqb0sZnT0TB1Q0irwg357tV5ay34L3KWczqGNlv1BOhwwG2vd"
                            }}
                        />
                        <hr/>
                        </>
                )}
        </>
}