import {Col, Form, Row} from "react-bootstrap";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import NotificationPopup from "../../components/NotificationPopup";
import "./login.css"
import {
    IonButton,
    IonButtons,
    IonContent,
    IonInput,
    IonItem,
    IonList,
    IonModal, IonTitle,
    IonToolbar, useIonAlert
} from "@ionic/react";

const Buttons = ({setPage}: any) => {

    const handleResetMail = () => {
        const email = document.getElementById("remail") as HTMLInputElement

        if (email && email.value && email.value.includes("@")) {
            const body = new FormData()
            body.append("remail", email.value)
            axios.post("https://nexarcore.com/account.php", body, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .catch(reason => dismiss())
                .then(e => {
                    presentAlert({
                        header: 'Sent',
                        message: 'Recovery email was sent to ' + email?.value + '. Please check your spam/junk and click the link in the email.',
                        buttons: [{
                            text: 'Will do',
                        }]})
                    dismiss()
                })
        }
        else {
            presentAlert({
                header: 'Error',
                message: 'Please provide a decent email address.',
                buttons: [{
                    text: 'Sure Thing',
            }]})
        }
    }

    const modal = React.useRef<HTMLIonModalElement>(null);

    const dismiss = () => {
        modal.current?.dismiss();
    }

    const [presentAlert] = useIonAlert()

    return (
        <>
            <div className={"login-title text-center"}>
                <h1 className={'title-text'}></h1>
            </div>
            <div className={"pad-4-horz"} style={{marginTop: '21rem'}}>
                <a style={{zIndex: 2, position: 'relative', top: '-1rem', cursor: 'pointer'}}
                   id={"open-modal"}>Forgot password/username</a>
                <div className={'btn-bluey round-btn mt-2 mb-2'} onClick={() => setPage('create')}>CREATE AN ACCOUNT
                </div>
                <div className={'btn-bluey round-btn'} onClick={() => setPage('login')}>LOG IN</div>
            </div>

            <IonModal id="example-modal" ref={modal} trigger="open-modal">
                <IonContent>
                    <IonToolbar>
                        <IonTitle>Recovery Email</IonTitle>
                        <IonButtons slot="end">
                            <IonButton color="light" onClick={() => dismiss()}>
                                Close
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>

                    <p className={"text-secondary"} style={{fontSize: '.7rem', padding: '1rem'}}>Please provide the email address you used when you registered your account. A recovery email will be sent to that address from NexarCore.</p>
                    <IonList>
                        <IonItem>
                            <IonInput label={"Email address"} id="remail" labelPlacement="stacked"/>
                        </IonItem>
                    </IonList>
                    <center>
                        <IonButton onClick={handleResetMail}>Send</IonButton>
                    </center>
                </IonContent>
            </IonModal>
        </>
    )
}

const LoginPage = ({setLogin}: any) => {
    const [page, setPage] = React.useState('buttons')
    const [notification, setNotification] = React.useState({show: false, text: '', title: ''})
    return (
        <>
            <div className={"login-bg"}/>
            <NotificationPopup notification={notification}/>
            <Row md={12} style={{position: 'relative', top: '-1rem', height: '100%'}}>
                <Col md={4} className={"justify-content-center login-buttons"}>
                    {page === 'buttons' && <Buttons setPage={setPage} />}
                    {page === 'login' && <UserLoggingIn setPage={setPage} setLogin={setLogin} setNotification={setNotification}/>}
                    {page === 'create' && <CreateUser setPage={setPage} setLogin={setLogin} setNotification={setNotification}/>}
                </Col>
                <Col md={8}/>
            </Row>
        </>
    )
}

const loginWithFields = (username: string, password: string, email: any, setLogin: any, setNotification: any) => {
    const username1 = document.getElementById(username) as HTMLInputElement
    const password1 = document.getElementById(password) as HTMLInputElement
    const email1:HTMLInputElement|null= document.getElementById(email) as HTMLInputElement
    if (username1 && password1) {
        const createUser = email1 ? "&email=" + email1.value + "&createme=" : ''
        const creds = email1 ? "&creds" : ""

        axios.post("https://nexarcore.com/account.php?api".concat(creds), "user=" + username1.value + "&pass=" + password1.value + createUser, {headers: {"Content-Type": 'application/x-www-form-urlencoded'}})
            .then(value => {
                if (value.data && value.data.output === 'success' && value.data.loginCredentials) {
                    localStorage.setItem('loginCredentials', value.data.loginCredentials)

                    setNotification({show: true, text: "Success! Logged in.", title: 'Logged in'})
                    setTimeout(() => {
                        setLogin({username: username1.value, login: true, credentials: value.data.loginCredentials})
                        window.location.href = "/collection"
                    }, 2000)
                }
                else {
                    setNotification({show: true, text: value.data.error || 'Oops. Something went wrong.', title: 'Failed to log in'})
                    setTimeout(() => {
                        setNotification({show: false, text: '', title: ''})
                    }, 3000)
                }
            })
            .catch(reason => setNotification({show: true, text: reason, title: 'Error logging in'}))
    }
}

const UserLoggingIn = ({setPage, setLogin, setNotification}: any) => {
    return (
        <>
            <div style={{zIndex: 2, position: 'relative'}}>
                <div className={"mb-2"} style={{cursor: 'pointer', marginLeft: '2rem'}} onClick={() => setPage('buttons')}><FontAwesomeIcon
                    icon={faArrowLeft} className={"fa-2x"}/></div>
                <div className={"login-title"}>
                    <div className={"bg-layout mt-3"}>
                        <div className={"text-secondary"}>Please provide your login credentials.</div>
                        <div className={"mt-5"}/>
                        <Form.Label htmlFor="username">Username</Form.Label>
                        <Form.Control
                            type="text"
                            id="username"
                            required
                        />

                        <div className={"mt-5"}/>
                        <Form.Label htmlFor="password">Password</Form.Label>
                        <Form.Control
                            type="password"
                            id="password"
                        />

                        <div className={"mt-5"}/>
                        <Form.Control
                            type="submit"
                            value={"Login"}
                            onClick={() => loginWithFields('username', 'password', null, setLogin, setNotification)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

const CreateUser = ({setPage, setLogin, setNotification}: any) => {
    return (
        <>
            <div style={{zIndex: 2, position: 'relative'}}>
                <div className={"mb-2"} style={{cursor: 'pointer', marginLeft: '2rem'}} onClick={() => setPage('buttons')}><FontAwesomeIcon
                    icon={faArrowLeft} className={"fa-2x"}/></div>
                <div className={"login-title"}>
                    <div className={"bg-layout mt-3"}>
                        <Form.Label htmlFor="username">Username</Form.Label>
                        <Form.Control
                            type="text"
                            id="username"
                            required={true}
                        />

                        <div className={"mt-5"}/>
                        <Form.Label htmlFor="email">Email</Form.Label>
                        <Form.Control
                            type="text"
                            id="email"
                        />

                        <div className={"mt-5"}/>
                        <Form.Label htmlFor="password">Password</Form.Label>
                        <Form.Control
                            type="password"
                            id="password"
                            aria-describedby="passwordHelpBlock"
                        />
                        <Form.Text id="passwordHelpBlock" muted>Please, for your sake, think of a strong password at least 8
                            characters long and containing some special characters. </Form.Text>

                        <div className={"mt-5"}/>
                        <Form.Control
                            type="submit"
                            value={"Create Account"}
                            onClick={() => loginWithFields('username', 'password', 'email', setLogin, setNotification)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage