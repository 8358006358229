import {Bug} from "../Types";
import React from "react";

export const BASE_PATH = "https://nexarcore.com/serverless/bug-base"

export const getNumbers = (n: number) => {
    const numbers = []
    for (let i = 0; i < n; i++) numbers.push(i)
    return numbers
}

export const generateHatches = (n: number, momImg: string, dadImg: string) => {
    const hatches = []
    for (let i = 0; i < n; i++) hatches.push({
        layDate: "",
        disperseDate: "",
        locale: "",
        motherImg: momImg,
        fatherImg: dadImg,
        notes: "",
        species: "",
        offspringNumber: "0",
        pairName: "",
        hatchNumber: 0
    })
    return hatches
}

export const generateBug = () => {
    return {
        name: "",
        sex: "-",
        species: "",
        locale: "",
        disperse: "",
        matured: "",
        instar: "",
        origin: "",
        notes: "",
        mother: "",
        father: "",
        img: ""
    }
}

export const generatePair = () => {
    return {
        "name": "",
        "parent-male": "",
        "parent-female": "",
        "history": "",
        "notes": "",
        localeMother: "",
        localeFather: "",
        pairingDates: [],
        layingDates: [],
        hatches: [],
        speciesMale: "",
        speciesFemale: "",
    }
}

export const generateDayType = (date: Date) => {
    return {
        date: date,
        watered: false,
        fed: false,
        cleaned: false,
        notes: ""
    }
}

export const getDataFromStorage = (key: string, creds: string) => {
    const saved = localStorage.getItem(creds.split("___")[0].concat(key)) || '[]'
    return JSON.parse(saved)
}

export const getObjDataFromStorage = (key: string, creds: string) => {
    const data = getDataFromStorage(key, creds)
    return data.length === 0 ? {} : data
}

export const getStringDataFromStorage = (key: string, creds: string) => {
    const data = getDataFromStorage(key, creds)
    return data.length === 0 ? '' : data
}

export const setDataToStorage = (key: string, creds: string, data: any) => {
    localStorage.setItem(creds.split("___")[0].concat(key), JSON.stringify(data))
}

export const constructImagePath = (image: string, dataDir: string) => {
    if (image.includes("icon/icon")) return image;
    return image.includes(dataDir) || !dataDir ? BASE_PATH + '/'.concat(image) : BASE_PATH + '/' + dataDir + '/'.concat(image)
}

export const hashCode = (s: string) => {
    let hash = 0, i, chr
    if (s.length === 0) return hash
    for (i = 0; i < s.length; i++) {
        chr = s.charCodeAt(i)
        hash = ((hash << 5) - hash) + chr
        hash |= 0
    }
    return hash
}

export const handleLogout = (setLogin, login, history) => {
    localStorage.removeItem("loginCredentials")
    setLogin({...login, login: false})
    history.push('/login')
}

export const donateSentences = [
    "Pay for caffeine rehab",
    "Support our coffee addiction",
    "Buy our bugs a treat",
    "Support future crazy ideas",
    "Send us a snack",
    "Fund our bug addiction",
    "Buy us a shred of sanity",
    "Buy us a new bug",
    "Pay for bug rehab",
    "The Spider Shop keeps taking our money. Help!",
    "I spent all my money on bugs",
    "Pay my bugs' rent",
    "My huntsman escaped, buy me a new house"
]

export const PRO_PRODUCT_ID = "pro"
export const ADD10BUGS_PRODUCT_ID = "add10bugs"

/*
easter egg - moving bug
 */

export const movingBugs = (bugs: Array<Bug>, dataDir: string) => (
    <div className={"moving-bugs"}>
        {bugs.map((b: Bug) => {
            return (
                <div key={b.img} id={"movingBug" + b.name} className={"moving-bug"} style={{
                    position: 'absolute',
                    left: Math.random()*300,
                    top: Math.random()*300+200,
                    transform: 'rotate(0deg)'
                }}
                     onClick={() => (document.getElementById("movingBug" + b.name) as HTMLElement).style.display = "none"}
                >
                    <img
                        src={b.img ? constructImagePath(b.img, dataDir) : BASE_PATH + '/app/assets/icon/icon.png'}
                        alt=''
                        style={{width: '45px', height: '45px'}}
                        className='rounded-circle'
                    />
                </div>
            )
        })}
    </div>
)

const xSpeed = 2
const ySpeed = 2
const rotateSpeed = 7
export const startMovingBugsInterval = () => {
    setInterval(function () {
        const movingBugs = document.getElementsByClassName("moving-bug") as HTMLCollectionOf<HTMLElement>
        for (let i = 0; i < movingBugs.length; i++) {
            if (Number(movingBugs[i].style.left.replace("px", "")) < 1000) {
                movingBugs[i].style.left = (Math.random() * xSpeed - xSpeed/2 + Number(movingBugs[i].style.left.replace("px", ""))) + "px";
                movingBugs[i].style.top = (Math.random() * ySpeed - ySpeed / 2 + Number(movingBugs[i].style.top.replace("px", ""))) + "px";
                movingBugs[i].style.transform = "rotate(" + (Number(movingBugs[i].style.transform.replace("rotate(", "").replace("deg)", "")) + Math.random()*rotateSpeed-rotateSpeed / 2) + "deg)";
            }
        }
    }, 70)
}

/*

 */