import React from 'react';
import {DayPageProps, DayType} from "../Types";
import NotificationPopup from "../components/NotificationPopup";
import {Col, Row, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {IonCheckbox, IonItem, IonList, IonTitle} from '@ionic/react';
import axios from "axios";
import {BASE_PATH} from "../util/tools";

export const DayPage: React.FC<DayPageProps> = ({currentDay, setCurrentDay, creds, days, setDays}) => {
    const [notification, setNotification] = React.useState({show: false, text: '', title: ''})

    const handleSave = () => {
        const notes = document.getElementById("notesDay") as HTMLInputElement
        const watered = document.getElementById("watered") as HTMLInputElement
        const fed = document.getElementById("fed") as HTMLInputElement
        const cleaned= document.getElementById("cleaned") as HTMLInputElement

        const newDay: DayType = {
            watered: watered?.checked,
            fed: fed?.checked,
            cleaned: cleaned?.checked,
            date: currentDay?.date || new Date(),
            notes: notes?.value || ''
        }

        if (setCurrentDay) {

            axios.post(BASE_PATH + "/day-api.php?api&creds=".concat(creds),
                newDay,
            )
                .then(() => {
                    setNotification({show: true, text: 'Saved day!', title: 'Saved Day'})
                    setTimeout(() => {
                            setNotification({show: false, text: '', title: ''})

                            const newDays = days?.filter((oldDay: DayType) => oldDay.date !== currentDay?.date)
                            newDays?.push(newDay)
                            setDays(newDays)
                            setCurrentDay(null)
                        }, 1500
                    )
                })
                .catch(e => {
                    setNotification({
                        show: true,
                        text: 'Error when saving day: '.concat(e.data.text),
                        title: 'Error adding day'
                    })
                    setTimeout(() => setNotification({show: false, text: '', title: ''}), 2000)
                })
        }
    }

    return (
        <>
            <NotificationPopup notification={notification}/>
            <Row md={12}>
                {setCurrentDay && (
                <Col md={12} style={{margin: '1rem'}}>
                    <div className={"mb-2"} style={{cursor: 'pointer', marginTop: '1rem', marginLeft: '-87%'}}
                         onClick={() => setCurrentDay(null)}><FontAwesomeIcon icon={faArrowLeft} className={"fa-2x"}/></div>

                </Col>
                )}
                <Col md={12} style={{margin: '1rem', width: '90%'}}>
                    <IonTitle size="large" style={{marginBottom: '2rem'}}>{currentDay?.date?.toDateString() || "Unknown"}</IonTitle>

                    <IonList>
                    <IonItem>
                        <IonCheckbox id="watered" name="watered" justify="space-between" checked={currentDay?.watered} disabled={!setCurrentDay}>Watered</IonCheckbox>
                    </IonItem>

                    <IonItem>
                        <IonCheckbox id="fed" name="fed" justify="space-between" checked={currentDay?.fed} disabled={!setCurrentDay}>Fed</IonCheckbox>
                    </IonItem>

                    <IonItem>
                        <IonCheckbox id="cleaned" name="cleaned" justify="space-between" checked={currentDay?.cleaned} disabled={!setCurrentDay}>Cleaned</IonCheckbox>
                    </IonItem>
                </IonList>
                </Col>

                <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                    <Form.Label htmlFor="notes">Notes</Form.Label>
                    <Form.Control
                        type="text"
                        id={"notesDay"}
                        required
                        placeholder={"Notes"}
                        value={currentDay?.notes}
                        disabled={!setCurrentDay}
                        onChange={(e) => setCurrentDay({...currentDay, notes: e.target.value,
                            watered: (document.getElementById("watered") as HTMLInputElement).checked,
                            fed: (document.getElementById("fed") as HTMLInputElement).checked,
                            cleaned: (document.getElementById("cleaned") as HTMLInputElement).checked
                        })}
                    />
                </Col>

                {setCurrentDay && (
                    <Col sm={12} style={{margin: '1rem', width: '90%'}}>
                        <div className={"mt-3"}/>
                        <Form.Control
                            type="submit"
                            value={"Save"}
                            disabled={!setCurrentDay}
                            onClick={handleSave}
                        />
                    </Col>
                )}
            </Row>
        </>
    )
}